import { createContext, useContext } from 'react';
import { ClientModuleTemplateDefault, ModuleDefaultConfiguration } from '../models/ClientModuleDefaults';
import { ManageClassesWizardStep } from '../components/documents/manage-classes/ManageClassWizard';
import User from '../models/User';
import { ModuleTemplate } from '../models/Module';

type SelectedClass = ClientModuleTemplateDefault | null;
type ManageDocumentClassesContext = {
  classes: ClientModuleTemplateDefault[];
  newTemplate: ModuleTemplate;
  setTemplate?: (value: ModuleTemplate | ((prev: ModuleTemplate) => ModuleTemplate)) => void;
  defaults: ModuleDefaultConfiguration;
  setDefaults?: (value: ModuleDefaultConfiguration | ((prev: ModuleDefaultConfiguration) => ModuleDefaultConfiguration)) => void;
  cancel?: () => void;
  nextStep?: (stepName: ManageClassesWizardStep) => void;
  prevStep?: () => void;
  stepNames?: readonly ManageClassesWizardStep[];
  clientUsers: User[];
  selectedClass: SelectedClass;
  setSelectedClass: (value: SelectedClass | ((prev: SelectedClass) => SelectedClass)) => void;
  onUpdated: (updatedClass: ClientModuleTemplateDefault) => void;
  onCreated: (newClass: ModuleTemplate) => void;
  reset: () => void;
  onUserInvited: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ManageDocumentClassesContext = createContext<ManageDocumentClassesContext>(null!);
export const useManageDocumentClassesWizard = (): ManageDocumentClassesContext => useContext(ManageDocumentClassesContext);
